<template>
  <div
    class="my-profile-modal-content"
    :class="{
      isMobile: $device.isMobile
    }"
  >
    <el-avatar class="avatar-primary" :src="userData?.user.avatarUrl" />

    <div class="sections">
      <div class="section-item">
        <span class="label-gray">{{ $t('profilePageDisplayedName') }}</span>
        <span class="label-white">{{ userName }}</span>
      </div>

      <div class="section-item">
        <span class="label-gray">{{ $t('profilePageWalletAddress') }}</span>
        <el-input
          class="input-primary"
          readonly
          :model-value="$device.isMobile && address ? formatAddress(address) : address"
        >
          <template v-if="isSupported" #suffix>
            <el-tooltip popper-class="profile-input-popper copied" placement="left" :visible="copied">
              <template #content>{{ $t('coreCopied') }}</template>
              <span @click.stop="copy"><Copy /></span> </el-tooltip
          ></template>
        </el-input>
      </div>

      <LoggedInWallet />

      <NuxtLink
        to="/profile"
        class="btn-primary"
        :class="{
          'ru-locale': $i18n.locale === 'ru'
        }"
      >
        <span> {{ $t('myProfileModalButton') }}</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { IUserModel } from '~/types/apiService';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import Copy from '~/components/icons/Copy.vue';
import LoggedInWallet from '~/components/LoggedInWallet.vue';
import { USER_NAME_MAX_LENGTH } from '~/utils/constants/profile';

const { isSupported } = useClipboard();
const { address } = useWeb3ModalAccount();
const { data: userData } = useNuxtData<IUserModel>('userData');
const { copy, copied } = useCopyClipboard(address);

const userName = computed(() => {
  if (address.value && userData.value?.user?.name && userData.value.user.name.length > USER_NAME_MAX_LENGTH) {
    return formatAddress(address.value);
  }

  return userData.value?.user.name || '';
});
</script>

<style lang="scss" scoped>
.my-profile-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;

  .btn-primary.ru-locale {
    font-size: 16px;
  }
}

.el-avatar.avatar-primary {
  width: 150px;
  height: 150px;
  min-width: 150px;
}

.label-gray {
  font-family: Grenze;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.label-white {
  font-family: Eczar;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 20px;
  width: 100%;

  .btn-primary {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 62px;
    font-size: 20px;
    text-wrap: nowrap;
    width: min-content;

    @media screen and (max-width: 767px) {
      height: 56px;
    }
  }
}

.section-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.el-popper.profile-input-popper {
  background-color: rgba(0, 0, 0, 0.85);
  border: 1px solid rgba(30, 53, 62, 1);
  border-radius: 15px;
  font-family: Grenze;
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  white-space: wrap;
  padding: 16px;
  width: 350px;

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }

  :before {
    display: none;
  }
}

.el-popper.profile-input-popper.copied {
  width: fit-content;
  font-size: 24px;
  line-height: 12px;
  height: 47px;
}

@media screen and (max-width: 767px) {
  .my-profile-modal-content.isMobile {
    padding-top: 0;

    .sections {
      margin-top: 16px;
      gap: 24px;

      .btn-primary {
        margin-top: 8px;
        width: 240px;
      }
    }

    .section-item {
      gap: 12px;
    }

    .input-primary {
      height: 56px;
    }
  }
}
</style>

<style>
.my-profile-modal-content {
  .input-primary {
    .el-input__inner {
      font-size: 19px;
      min-width: 480px;

      @media screen and (max-width: 767px) {
        min-width: 200px;
      }
    }
    .el-input__wrapper {
      padding: 13px 12px 13px 20px;
    }

    .el-input__suffix-inner {
      span {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  .logged-in-wallet {
    gap: 8px;
    flex-direction: column;
  }
}
</style>
