<template>
  <span v-if="messageType === CraftingQueueStatusMessage.COMMON_QUEUE_OVERLOAD">
    <i18n-t keypath="craftingQueueLimitExceeded" tag="p">
      <template #limit>
        <CraftingQueueLimitCountDown :time="queueTimeLimit" />
      </template>
    </i18n-t>
    <p v-html="$t('craftingQueueLimitExceeded2')"></p>
  </span>

  <span v-else>
    <i18n-t keypath="craftingPersonalQueueLimitExceeded" tag="p">
      <span v-if="isLoading">-</span>
      <span v-else></span>
      <template #limit>
        <el-skeleton :loading="isLoading" animated>
          <template #template>
            <el-skeleton-item style="height: 20px" />
          </template>
          <template #default>
            (
            <CraftingQueueLimitCountDown :time="queueTimeLimitPerUser" />
            )
          </template>
        </el-skeleton>
      </template>
    </i18n-t>
    <p v-html="$t('craftingPersonalQueueLimitExceeded2')" />
  </span>
</template>
<script setup lang="ts">
import CraftingQueueLimitCountDown from '~/components/Crafting/CraftingQueueLimitCountDown.vue';
import { computed, inject } from 'vue';
import { CraftingQueueStatusMessage } from '~/utils/constants';
import type { BuildingProps, RecipeProps } from '~/types/crafting';
import { BigNumber } from 'bignumber.js';

const { isMobile } = useDevice();
const route = useRoute();
const building = inject<Ref<BuildingProps>>('building');

defineProps<{
  messageType: CraftingQueueStatusMessage;
}>();

const { data: recipeToCraft } = useNuxtData<RecipeProps>('recipes/recipe');
const { data: recipes } = useNuxtData<RecipeProps[]>(
  `buildingRecipes${isMobile && route.name !== 'districts' ? building?.value.buildingHash : ''}`
);

const queueTimeLimit = computed(() => {
  return BigNumber(recipeToCraft.value?.duration || 0)
    .multipliedBy(1000)
    .multipliedBy(recipes.value?.find((recipe) => recipe.recipeId === recipeToCraft.value?.recipeId)?.maxQueue || 0)
    .toNumber();
});

const { maxQueuePerUser, isLoading } = await useMaxQueuePerUser(recipeToCraft, building);

const queueTimeLimitPerUser = computed(() => {
  return BigNumber(recipeToCraft.value?.duration || 0)
    .multipliedBy(1000)
    .multipliedBy(maxQueuePerUser.value)
    .toNumber();
});
</script>
