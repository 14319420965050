<template>
  <div class="available-claim">
    <div class="available-claim-info">
      <div v-if="isSingleProductRecipe" class="recipe-row-token-info">
        <img
          :src="getIcon(Number(props.recipeId), recipe?.products[0].tokenAddress || '')"
          :alt="props.tokenMetadata.label"
        />
        <h3>({{ props.runningRecipe.multiplyModifier }})</h3>
      </div>
      <div v-else class="recipe-row-token-info">
        <img
          :src="getIcon(Number(props.recipeId), recipe?.products[0].tokenAddress || '')"
          :alt="props.tokenMetadata.label"
        />
        <h3>{{ $t(`${recipeIdPrefix}${recipe?.recipeId}`) }} ({{ props.runningRecipe.multiplyModifier }})</h3>
      </div>

      <div class="recipe-row-token-info">
        <vue-countdown
          v-slot="{ days, hours, minutes, seconds }"
          :time="countdownTimestamp"
          @end="endTimerHandler"
          @progress="
            ({ totalMilliseconds }) => {
              percent = 100 - (totalMilliseconds / (runningRecipe.craftDuration * 1000)) * 100;
              timerTokensForClaimRef = Math.floor(
                calculateTokensAvailable(
                  totalMilliseconds,
                  runningRecipe.craftDuration * 1000,
                  runningRecipe.multiplyModifier
                )
              );
            }
          "
        >
          {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
          {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
          {{
            BigNumber(minutes).isGreaterThan(0) || BigNumber(hours).isGreaterThan(0)
              ? `${formatToZeroPrefixedNumber(minutes || 0)}m`
              : ''
          }}
          {{
            BigNumber(hours).isGreaterThan(0) || (BigNumber(minutes).isZero() && BigNumber(seconds).isZero())
              ? ''
              : `${formatToZeroPrefixedNumber(seconds || 0)}s`
          }}
        </vue-countdown>
      </div>
    </div>
    <div
      :class="[
        'available-claim-progress',
        percent <= CRAFTING_PERCENT ? 'default' : 'halfDone',
        !isMineRecipe && 'notMine'
      ]"
    >
      <p class="available-claim-progress-percent">{{ percent >= 100 ? '100' : percent.toFixed(2) }}%</p>
      <div v-for="(_, index) of barsAmount" :key="index" class="available-claim-progress-bar" />
      <div
        :class="[
          'available-claim-progress-fill',
          percent <= CRAFTING_PERCENT ? 'default' : 'halfDone',
          !isMineRecipe && 'notMine'
        ]"
        :style="{
          width: `${percent}%`
        }"
      />
    </div>
    <div v-if="isMineRecipe && recipe?.products && !isSingleRecipe" class="available-claim-container">
      <div class="btn-outline">
        <div v-for="product of recipe.products" :key="product.tokenAddress" class="btn-outline-block">
          <img
            :src="
              getIcon(
                Number(props.recipeId),
                product.tokenAddress || '',
                product.nftValueMax && recipe.products.length === 1 ? false : true
              )
            "
            :alt="props.tokenMetadata.label"
          />
          <div v-if="isNft" class="available-claim-item">
            <span class="available-claim-item-counter">{{ tokensForClaimRef }}</span>
          </div>
          <span
            v-else-if="new BigNumber(product.quantityMin).eq(product.quantityMax)"
            class="available-claim-item-counter"
          >
            {{ new BigNumber(product.quantityMax).multipliedBy(tokensForClaimRef) }}
          </span>
          <span v-else class="available-claim-item-counter">
            {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(tokensForClaimRef) }}-{{
              new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(tokensForClaimRef)
            }}
          </span>
        </div>
      </div>
      <button
        class="btn-primary"
        type="button"
        :disabled="tokensForClaimRef <= 0 || isLoadingClaimButton"
        @click="claimCraftedTokens(props.runningRecipe.userRecipeIndex)"
      >
        <span>{{ $t('craftingClaim') }} </span>
        <img src="/img/icons/chevronRight.svg" alt="chevron right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed, inject, watch } from 'vue';
import { ElNotification } from 'element-plus';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { useRuntimeConfig } from 'nuxt/app';
import { type TTokensConfigItem, CRAFTING_PERCENT } from '~/utils/constants';
import type { RunningRecipeProps, FormattedActivityItemProps, RecipeProps, BuildingProps } from '~/types/crafting';
import useSendContractMethod from '~/composables/useSendContractMethod';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import { modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import { useI18n } from '#imports';
import { calculateTimeLeftPercent } from '~/utils/crafting';

const { t } = useI18n();
const { address } = useWeb3ModalAccount();
const { sendContractMethod } = useSendContractMethod();
const { blockchain, apiUrl } = useEnvs();
const store = useMainStore();
const config = useRuntimeConfig();

const props = defineProps<{
  tokenMetadata: TTokensConfigItem;
  runningRecipe: RunningRecipeProps;
  recipeId: string;
}>();

const { data: recipe } = await useFetch<RecipeProps>(() => `/crafting/recipes/${props.recipeId}`, {
  baseURL: apiUrl,
  watch: [() => props.recipeId]
});

const endTimerHandler = () => {
  refreshNuxtData('recipes/in-queue');
  refreshNuxtData('unclaimed-recipes');
};

const refreshActivityListInjected = inject<() => Promise<FormattedActivityItemProps[]>>('refreshList');
const building = inject<Ref<BuildingProps>>('building');

const isNft = computed(() => props.tokenMetadata.interface === 'erc721');
const endTime = computed(() => new Date(props.runningRecipe.craftEndTime).getTime());
const startTime = computed(() => new Date(props.runningRecipe.craftStartTime).getTime());
const isSingleRecipe = computed(() => props.runningRecipe.multiplyModifier === 1);
const isSingleProductRecipe = computed(() => recipe.value?.products.length === 1);
const recipeIdPrefix = computed(() => (config?.public.env === 'prod' ? 'prodRecipeId' : 'testnetRecipeId'));

const calculateTokensAvailable = (timeRemaining: number, totalDuration: number, totalTokens: number) => {
  const elapsedTime = totalDuration - timeRemaining;

  const durationPerToken = totalDuration / totalTokens;

  return Math.floor(elapsedTime / durationPerToken);
};

const percent = ref<number>(calculateTimeLeftPercent(startTime.value, endTime.value, Date.now()));
const countdownTimestamp = computed(() => endTime.value - Date.now());
const timerTokensForClaimRef = ref<number>(0);
const tokensForClaimRef = ref<number>(0);
const isLoadingClaimButton = ref<boolean>(false);
const isMineRecipe = ref<boolean>(false);

watch(
  () => props.runningRecipe.userAddress,
  (value) => {
    isMineRecipe.value = value === address.value;
  },
  {
    immediate: true
  }
);

watch([timerTokensForClaimRef, () => store.notificationUpdateVersion], async () => {
  if (refreshActivityListInjected) {
    const activities = await refreshActivityListInjected();
    refreshNuxtData('unclaimed-recipes');
    const currentActivityItem = activities.flat().find((item: any) => {
      return (
        props.runningRecipe.userRecipeIndex === item.id &&
        building?.value.buildingHash &&
        startTime.value === item.date * 1000
      );
    });
    console.log(currentActivityItem);

    tokensForClaimRef.value = currentActivityItem ? currentActivityItem.available : 0;
  }
});

const barsAmount = computed(() => {
  return [...new Array(props.runningRecipe.multiplyModifier - 1)];
});

const claimCraftedTokens = async (id: number) => {
  isLoadingClaimButton.value = true;
  try {
    const claimTx = await sendContractMethod({
      contract: 'crafting',
      address: blockchain.contracts.crafting,
      methodName: 'claimRecipe',
      methodArguments: [props.recipeId, id]
    });

    if (!claimTx?.hash) return;
    const txReceipt = await claimTx?.wait();
    if (txReceipt) {
      saveHashToLocalStorage(`${t('claimingRecipesNotification')}`, claimTx.hash);
    }
    store.updateVersion();

    modifyHistoryHashStatus(claimTx.hash, 'Done');
    store.updateVersion();
    refreshActivityListInjected && refreshActivityListInjected();
    tokensForClaimRef.value = 0;
  } catch (error) {
    console.error(error, 'error on claim');
    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  } finally {
    isLoadingClaimButton.value = false;
  }
};
</script>

<style lang="scss" scoped>
.available-claim {
  padding: 0 14px 14px 14px;
  margin-bottom: 6px;

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 40px;
      width: 40px;
    }

    h3 {
      font-family: 'Grenze';
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: #fff;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    p {
      font-family: Grenze;
      font-size: 24px;
      font-weight: 500;
      line-height: 35.52px;
      text-align: left;
      color: #fff;
    }
  }

  &-progress {
    position: relative;
    border-radius: 8px;
    border: 1px solid #49ddaa;
    border-radius: 8px;
    margin-bottom: 10px;
    height: 25px;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    transition: all 0.3s ease;
    background: linear-gradient(0deg, #010303, #17343e);

    &.notMine {
      border-color: #10364a;
    }

    &-percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: Grenze;
      font-size: 18px;
      font-weight: 900;
      line-height: 19.6px;
      color: #fff;
      z-index: 3;
    }

    &-bar {
      height: 100%;
      width: 2px;
      background: #fff;
      opacity: 0.2;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
    }

    &-fill {
      height: 100%;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;

      background: linear-gradient(90deg, #01c0fe 0%, #60ff7c 100%);

      &.notMine {
        background: linear-gradient(90deg, #c9c9c9 0%, rgba(201, 201, 201, 0.5) 99.89%);
      }
    }
  }

  &-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn-primary {
      margin-left: 10px;
      display: flex;
      align-items: center;

      span {
        word-wrap: normal;
      }

      @media screen and (max-width: 767px) {
        line-height: 0px;
      }
    }

    .btn-outline {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 10px;
      border-color: rgba(201, 201, 201, 0.5);
      min-width: fit-content;
      background: radial-gradient(circle, rgba(4, 15, 20, 1) 0%, rgba(8, 27, 37, 1) 100%);
      color: #49ddaa;
      pointer-events: none;
      user-select: none;
      padding: 2px 10px;

      &-block {
        display: flex;
        align-items: center;

        span {
          padding-left: 3px;
          word-wrap: normal;
        }
      }

      img {
        height: 40px;
        min-height: 40px;
        min-width: 40px;
      }

      .available-claim-item {
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          padding-right: 5px;
        }
      }

      .available-claim-item-counter {
        font-size: 20px;
      }
    }
  }
}
</style>
