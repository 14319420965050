<template>
  <vue-countdown ref="countdownRef" v-slot="{ days, hours, minutes, seconds }" :time="time" @progress="progressHandler">
    {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
    {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
    {{
      BigNumber(minutes).isGreaterThan(0) ||
      (BigNumber(minutes).isLessThanOrEqualTo(0) && BigNumber(seconds).isLessThanOrEqualTo(0))
        ? `${formatToZeroPrefixedNumber(minutes)}m`
        : ''
    }}
    {{
      BigNumber(hours).isGreaterThan(0) || (BigNumber(minutes).isZero() && BigNumber(seconds).isZero())
        ? ''
        : `${formatToZeroPrefixedNumber(seconds)}s`
    }}
  </vue-countdown>
</template>
<script setup lang="ts">
import { BigNumber } from 'bignumber.js';
import VueCountdown from '@chenfengyuan/vue-countdown';

defineProps<{
  time: number;
}>();

const { data: runningRecipes } = useNuxtData('recipes/in-queue');
const setQueueTimeInjected = inject<(value: number) => void>('setQueueTime');

const progressHandler = ({ totalMilliseconds }: { totalMilliseconds: number }) => {
  const queueTime = runningRecipes.value && runningRecipes.value.length > 0 ? totalMilliseconds : 0;
  setQueueTimeInjected && setQueueTimeInjected(queueTime);
};
</script>
