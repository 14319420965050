<template>
  <span class="inline-container">
    <span v-if="BigNumber(time).isZero() || !BigNumber(time).isPositive()">0</span>
    <vue-countdown
      v-else
      ref="countdownRef"
      v-slot="{ days, hours, minutes }"
      :time="time"
      :auto-start="false"
      :emit-events="false"
    >
      {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : null }}
      {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : null }}
      {{
        BigNumber(days).isLessThan(1) && BigNumber(minutes).isGreaterThan(0)
          ? `${formatToZeroPrefixedNumber(minutes)}m`
          : null
      }}
    </vue-countdown>
  </span>
</template>
<script setup lang="ts">
import { BigNumber } from 'bignumber.js';
import VueCountdown from '@chenfengyuan/vue-countdown';

defineProps<{
  time: number;
}>();

const countdownRef = ref<InstanceType<typeof VueCountdown> | null>(null);
const abortCountDown = () => {
  if (countdownRef.value) {
    countdownRef.value.abort();
  }
};
defineExpose({
  abortCountDown
});
</script>

<style scoped lang="scss">
.inline-container {
  display: inline-flex;
}
</style>
