<template>
  <li class="crafting-material">
    <img class="crafting-material-img" :src="tokens[token.name.contract as Tokens].toString()" :alt="token.label" />
    <p class="placeholderWidthString">{{ placeholderWidthString }}</p>
    <p class="crafting-material-amount">
      <span :class="[!isEnoughTokens && 'notEnough']">{{ availableTokensFormatted }}</span
      >/<span>{{ BigNumber(props.ingredient.quantity).multipliedBy(produceAmount).toNumber() }}</span>
    </p>
  </li>
</template>

<script setup lang="ts">
import { defineProps, computed, ref } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

import { tokenMetaData } from '~/utils';
import type { RecipeIngredient } from '~/types/crafting';
import useEnvs from '~/composables/useEnvs';

import { useTokensReader } from '#imports';
import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import type { Tokens } from '~/utils/constants';

const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();
const { availableTokens } = useTokensReader();
const store = useMainStore();
const currentlyAvailableTokens = ref<number>(0);
const isEnoughTokens = ref<boolean>(false);
const isTokenBalanceLoading = ref<boolean>(true);

const props = defineProps<{
  ingredient: RecipeIngredient;
  produceAmount: number;
  placeholderWidthString: string;
}>();

const token = tokenMetaData(blockchain.contracts, props.ingredient.tokenAddress);

store.$onAction(async ({ name }) => {
  if (name === 'updateVersion') {
    currentlyAvailableTokens.value = address?.value
      ? Number(await availableTokens(address?.value, props.ingredient.tokenAddress))
      : 0;
  }
});

watch(
  address,
  async () => {
    const totalTokensOnBalance = address?.value
      ? await availableTokens(address?.value, props.ingredient.tokenAddress)
      : 0;
    isTokenBalanceLoading.value = false;

    const requiredAmountForCraft = props.ingredient.quantity;

    currentlyAvailableTokens.value = +totalTokensOnBalance;

    isEnoughTokens.value = BigNumber(totalTokensOnBalance).isGreaterThanOrEqualTo(
      BigNumber(requiredAmountForCraft).multipliedBy(props.produceAmount)
    );
  },
  { immediate: true }
);

const availableTokensFormatted = computed(() => {
  const absNum = Math.abs(currentlyAvailableTokens.value);

  if (absNum >= 1e6) {
    return (currentlyAvailableTokens.value / 1e6).toFixed(1).replace(/\.?0+$/, '') + 'M';
  } else if (absNum >= 1e3) {
    return (currentlyAvailableTokens.value / 1e3).toFixed(1).replace(/\.?0+$/, '') + 'k';
  } else {
    return BigNumber(currentlyAvailableTokens.value).integerValue(BigNumber.ROUND_DOWN);
  }
});
</script>

<style scoped lang="scss">
.notEnough {
  color: red;
}

.placeholderWidthString {
  visibility: hidden;
  line-height: 0;
  font-size: 18px;
}
</style>
