import type { BuildingProps, RecipeProps } from '~/types/crafting';
import { BigNumber } from 'bignumber.js';
import { ref } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { useMainStore } from '~/stores/mainStore';

export default () => {
  const store = useMainStore();
  const { availableTokens } = useTokensReader();
  const { address } = useWeb3ModalAccount();
  const { blockchain } = useEnvs();
  const { getContractReadOnly } = useAbiAccess();
  // const { data: runningRecipes } = useNuxtData('recipes/in-queue');
  const { data: recipeToCraft } = useNuxtData<RecipeProps>('recipes/recipe');
  const building = inject<Ref<BuildingProps>>('building');

  const maxAmountAvailableForCraft = ref(0);
  const isEnoughTokens = ref(false);
  const isLoading = ref(false);

  watch(
    [address, recipeToCraft, () => store.notificationUpdateVersion],
    async () => {
      try {
        isLoading.value = true;

        const contract = await getContractReadOnly('crafting', blockchain.contracts.crafting);

        const maxQueue = await contract.getMaxQueueForUserInBuilding(
          address.value,
          building?.value.buildingHash,
          recipeToCraft.value?.recipeId
        );

        const balanceAbilityToProduce: Promise<number>[] | undefined = recipeToCraft.value?.ingredients.map(
          async (ingredient) => {
            const ingredientBalance = !address.value
              ? 0
              : await availableTokens(address.value, ingredient.tokenAddress);

            return new BigNumber(ingredientBalance).dividedBy(new BigNumber(ingredient.quantity)).toNumber();
          }
        );

        const promises: number[] = balanceAbilityToProduce ? await Promise.all(balanceAbilityToProduce) : [];

        if (promises.some((tokenProduceAbility) => tokenProduceAbility < 1)) {
          isEnoughTokens.value = false;
        } else {
          isEnoughTokens.value = true;
        }

        const minRecipesCount = Math.floor(Math.min(...promises));

        const maxQueueNumber = BigNumber(maxQueue).toNumber();
        maxAmountAvailableForCraft.value = minRecipesCount >= maxQueueNumber ? maxQueueNumber : minRecipesCount;
      } catch (error) {
        console.error(error, 'error on getting maxAmountAvailableForCraft');
      } finally {
        isLoading.value = false;
      }
    },
    {
      immediate: true
    }
  );

  return { maxAmountAvailableForCraft, isLoading, isEnoughTokens };
};
