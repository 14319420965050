export const enum Buildings {
  GoldOreSmelter = 'gold_ore_smelter',
  Jewelry = 'jewelry',
  Dock = 'dock',
  BlackSmith = 'blacksmith',
  Sawmill = 'sawmill',
  ToymakerShop = 'toymaker_shop',
  Plantation = 'plantation',
  Warehouse = 'warehouse',
  Townhall = 'townhall',
  IronOreMine = 'iron_ore_mine',
  CoalMine = 'coal_mine',
  GoldOreMine = 'gold_ore_mine',
  IronOreSmelter = 'iron_ore_smelter',
  Barbershop = 'barbershop',
  CeramicWorkshop = 'ceramic_workshop',
  ClayQuarry = 'clay_quarry',
  SandQuarry = 'sand_quarry'
}
