<template>
  <div class="ready-for-claim" :class="props.claimItems.length === 1 ? 'single-row-wrapper' : null">
    <el-checkbox-group v-model="checkedIds" class="checkbox-group" size="large">
      <div v-for="(claimItem, index) of props.claimItems" :key="index" class="ready-for-claim-item">
        <el-checkbox v-if="multipleItems" type="checkbox" size="large" class="checkbox claim-checkbox" :label="index">
        </el-checkbox>

        <figure class="crafting-item-figure">
          <img
            :src="getIcon(Number(claimItem.recipe.recipeId), claimItem.recipe.products[0]?.tokenAddress)"
            :class="{ nftBackground: isNft(claimItem.recipe.products) }"
            :alt="tokenMetadataComputed?.label"
          />
        </figure>
        <span v-if="!isNft(claimItem.recipe.products)">
          <template
            v-if="new BigNumber(claimItem.recipe.products[0].quantityMin).eq(claimItem.recipe.products[0].quantityMax)"
          >
            {{ new BigNumber(claimItem.recipe.products[0].quantityMax).multipliedBy(claimItem.available) }}
          </template>
          <template v-else>
            {{
              new BigNumber(claimItem.recipe.products[0].quantityMin)
                .decimalPlaces(1)
                .multipliedBy(claimItem.available)
            }}-{{
              new BigNumber(claimItem.recipe.products[0].quantityMax).decimalPlaces(1).multipliedBy(claimItem.available)
            }}
          </template>
        </span>

        <span v-else>
          {{ claimItem.available }}
        </span>
      </div>
    </el-checkbox-group>

    <div class="buttons">
      <button v-if="multipleItems" class="btn-outline btn-select-all" @click="toggleAll()">
        {{ checkedIds.length === props.claimItems.length ? $t('coreUnselectAll') : $t('coreSelectAll') }}
      </button>
      <button
        class="btn-primary"
        type="button"
        :disabled="!checkedItems.length || isLoadingClaimButton"
        @click="claimCraftedTokens()"
      >
        <span>{{ $t('appStatisticClaim') }}</span>
        <span class="claim-icon" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, inject, computed } from 'vue';
import { type TTokensConfigItem } from '~/utils/constants';
import type { CraftAvailableForClaim, ProductMobile } from '~/types/crafting';
import { ElNotification } from 'element-plus';
import { BigNumber } from 'bignumber.js';
import { useI18n } from '#imports';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import { modifyHistoryHashStatus } from '~/utils';
import useSendContractMethod from '~/composables/useSendContractMethod';
import type { TNullable } from '~/types/common';
import { encodeIndexes } from '~/utils/crafting';

const { sendContractMethod } = useSendContractMethod();
const refreshListInjected = inject<() => void>('refreshList');
const refreshUnclaimedInjected = inject<() => void>('refreshUnclaimed');

const { blockchain } = useEnvs();
const store = useMainStore();

const confirmationNumberRef = ref<number>(0);
const isLoadingClaimButton = ref<boolean>(false);
const { t } = useI18n();
const checkedItems = ref<CraftAvailableForClaim[]>([]);

const props = defineProps<{
  claimItems: CraftAvailableForClaim[];
  tokenMetadataComputed: TNullable<TTokensConfigItem>;
  disabled: boolean;
}>();

const emit = defineEmits<{ claimLoadStateChanged: [isLoading: boolean] }>();

watch(isLoadingClaimButton, (newIsLoadingClaim) => emit('claimLoadStateChanged', newIsLoadingClaim));

const toggleAll = () => {
  checkedItems.value = isAllChecked.value ? [] : [...props.claimItems];
};

const checkedIds = computed({
  get: () => checkedItems.value.map((item) => props.claimItems.indexOf(item)),
  set: (indices: number[]) => {
    checkedItems.value = indices.map((i) => props.claimItems[i]).filter(Boolean);
  }
});

const allItems = computed(() => props.claimItems);
const isAllChecked = computed(
  () =>
    checkedItems.value.length === allItems.value.length &&
    checkedItems.value.every((item) => allItems.value.includes(item))
);

const multipleItems = computed(() => props.claimItems.length > 1);

watch(
  multipleItems,
  () => {
    if (multipleItems.value) return;

    toggleAll();
  },
  {
    immediate: true
  }
);

const isNft = (products: ProductMobile[]) => {
  return products.every((product: ProductMobile) => product.nftValueMin !== null && product.nftValueMax !== null);
};

const claimCraftedTokens = async () => {
  isLoadingClaimButton.value = true;

  try {
    const recipeIds = checkedItems.value.map((checkedItem: CraftAvailableForClaim) => checkedItem.recipe.recipeId);
    const recipeIndexes = checkedItems.value.map((checkedItem: CraftAvailableForClaim) => checkedItem.id) as Record<
      number,
      number
    >[];

    const [recipesFormatted, indexesFormatted] = splitActivitiesToChunks(recipeIds, recipeIndexes);
    const recipeIndexesEncoded = encodeIndexes(indexesFormatted);

    const claimTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'claimRecipes',
        methodArguments: [recipesFormatted, recipeIndexesEncoded]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;

    const txReceipt = await claimTx?.wait();

    if (txReceipt) {
      const recipesCount = checkedItems.value.length;
      saveHashToLocalStorage(`${t('claimingRecipesNotification', { count: recipesCount })}`, claimTx.hash);
    }

    store.updateVersion();

    await claimTx?.wait();

    modifyHistoryHashStatus(claimTx.hash, 'Done');
    store.updateVersion();
    confirmationNumberRef.value = 0;
    isLoadingClaimButton.value = false;
    checkedItems.value = [];
    refreshListInjected && refreshListInjected();
    refreshUnclaimedInjected && refreshUnclaimedInjected();

    ElNotification.success({
      title: t('successfulCraft'),
      message: ''
    });
  } catch (error) {
    console.error(error, 'error on claim');
    isLoadingClaimButton.value = false;

    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  }
};
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    flex-wrap: nowrap;
  }

  button {
    min-width: fit-content;
  }

  .btn-select-all {
    border-radius: 12px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}

.checkbox::v-deep(.el-checkbox__inner) {
  background-color: #005a24;
  margin-right: 10px;
  height: 16px;
  width: 16px;

  &::after {
    height: 9px;
    left: 5px;
    border-width: 2px;
  }
}

.checkbox::v-deep(.el-checkbox__label) {
  display: none;
}

.checkbox::v-deep(.is-checked) {
  .el-checkbox__inner {
    background: linear-gradient(180deg, #005a24 0%, #00a321 50%, #00e921 100%);
  }
}

.checkbox::v-deep(.is-focus),
.checkbox::v-deep(.is-checked) .el-checkbox__inner,
.checkbox::v-deep(.el-checkbox__input:hover) .el-checkbox__inner,
.checkbox::v-deep(.el-checkbox__input:active) .el-checkbox__inner {
  border-color: #00a321;
}

// TODO: create button claim
.claim-icon {
  background-image: url('~/public/img/icons/login-arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 15px;
  transition: background-image 0.3s ease;
}

.btn-primary {
  display: flex;
  align-items: center;
  grid-column-gap: unset;
  gap: 8px;

  &:not(:disabled):hover {
    .claim-icon {
      background-image: url('~/public/img/icons/login-arrow-hover.svg');
    }
  }
}

.ready-for-claim {
  border-radius: 12px;

  button {
    height: 40px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      margin-left: 8px;
      path {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    width: 75%;
  }
}

.single-row-wrapper {
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 7px;

    .checkbox-group {
      grid-template-columns: unset;
      margin-right: 10px;
    }

    .buttons {
      padding-top: unset;
    }
  }
}

.crafting-item-figure {
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  font-family: Eczar, sans-serif;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 800;
    font-size: 24px;
    color: #fff;
    text-wrap: nowrap;
  }

  img {
    width: 100%;
  }
}
</style>
