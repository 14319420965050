<template>
  <span class="crafting-queue-time-limit-tooltip" :class="{ 'ru-locale': $i18n.locale === 'ru' }">
    <p>{{ $t('craftingQueueLimitTooltipMessage1') }}</p>
    <span class="popper-highlighted-text">{{ $t('craftingQueueLimitTooltipMessageText') }}</span>
    <span class="skeleton">
      <el-skeleton :loading="isLoading" animated style="width: 60px; max-width: 60px">
        <template #template>
          <el-skeleton-item style="width: 60px; height: 20px" />
        </template>
        <template #default>
          <CraftingQueueLimitCountDown class="popper-highlighted-text" :time="queueTimeLimitPerUser" />
          <span class="popper-highlighted-text">.</span>
        </template>
      </el-skeleton></span
    >

    <p v-html="$t('craftingQueueLimitTooltipMessage2')" />
    <p v-html="$t('craftingQueueLimitTooltipMessage3')" />

    <br v-if="!isPersonalQueue" />

    <i18n-t v-if="!isPersonalQueue" keypath="craftingQueueLimitTooltipMessage4" tag="p">
      <template #countdown>
        <br />(<CraftingQueueLimitCountDown v-if="queueTimeLimit" :time="queueTimeLimit" />)
      </template>
    </i18n-t>

    <p v-if="!isPersonalQueue" v-html="$t('craftingQueueLimitTooltipMessage5')" />
  </span>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import type { BuildingProps, RecipeProps } from '~/types/crafting';
import { BigNumber } from 'bignumber.js';
import { QueueTypes } from '~/utils/constants';

const building = inject<Ref<BuildingProps>>('building');
const { isMobile } = useDevice();
const route = useRoute();

const { data: recipes } = useNuxtData<RecipeProps[]>(
  `buildingRecipes${isMobile && route.name !== 'districts' ? building?.value.buildingHash : ''}`
);
const { data: recipeToCraft } = useNuxtData<RecipeProps>('recipes/recipe');
const { maxQueuePerUser, isLoading } = await useMaxQueuePerUser(recipeToCraft, building);

const queueTimeLimit = computed(() => {
  return BigNumber(recipeToCraft.value?.duration || 0)
    .multipliedBy(1000)
    .multipliedBy(recipes.value?.find((recipe) => recipe.recipeId === recipeToCraft.value?.recipeId)?.maxQueue || 0)
    .toNumber();
});

const queueTimeLimitPerUser = computed(() => {
  return BigNumber(recipeToCraft.value?.duration || 0)
    .multipliedBy(1000)
    .multipliedBy(maxQueuePerUser.value)
    .toNumber();
});

const isPersonalQueue = computed(() => recipeToCraft.value?.queueType === QueueTypes.PERSONAL);
</script>

<style lang="scss" scoped>
.skeleton {
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  max-height: 20px;

  @media (max-width: 376px) {
    margin-top: 5px;
  }
}
</style>
