import type { Tokens } from '~/utils/constants';
import type { Buildings } from '~/utils/constants/buildings';

export enum RecipeIngredientAction {
  BURN = 'BURN',
  VERIFIED_TRANSFER = 'VERIFIED_TRANSFER',
  TRANSFER = 'TRANSFER'
}

export enum CraftingItemTab {
  RECIPE = 'recipe',
  CRAFTING = 'crafting'
}

export interface RecipeProduct {
  productId: number;
  tokenAddress: string;
  quantityMin: string;
  quantityMax: string;
  nftValueMax: null | number;
  nftValueMin: null | number;
}

export interface RecipeIngredient {
  ingredientId: number;
  tokenAddress: string;
  quantity: string;
  action: RecipeIngredientAction;
}

export interface RecipeProps {
  recipeId: string;
  duration: number;
  ingredients: RecipeIngredient[];
  products: RecipeProduct[];
  queueType: number;
  activated?: boolean;
  maxQueue?: number;
}

export interface RecipeViewersCount {
  viewersCount: number;
}

export interface RunningRecipeProps {
  userAddress: string;
  multiplyModifier: number;
  craftStartTime: string;
  craftEndTime: string;
  craftDuration: number;
  userRecipeIndex: number;
}

export interface RecipesPendingProps {
  transactionHash: string;
}

export interface BuildingProps {
  buildingName: string;
  buildingKey: Buildings;
  buildingHash: string;
  buildingId: string;
  descriptionKey: string;
  miningToken?: Tokens;
}

export interface ActivityItemProps {
  tokenAddress: string;
  amount: number;
  timestamp: string;
}

export interface UserCraftingActivityProps {
  recipeId: string;
  ingredients: ActivityItemProps[];
  products: ActivityItemProps[];
}

export interface CraftAvailableForClaim {
  available: number;
  id: number[];
  recipe: UnclaimedRecipeProps;
}

export interface UserActivityAvailableForClaim {
  index: number;
  date: number;
  total: number;
  acquired: number;
  available: number;
  buildingHash: string;
  id: number;
}

export interface FormattedActivityItemProps {
  date: number;
  total: number;
  acquired: number;
  available: number;
  buildingHash: string;
  id: number;
}

export interface BuildingDataType {
  buildingKey: string;
  products?: string[];
  recipes?: RecipeProps[];
  formattedValue?: UserActivityAvailableForClaim[];
}

export interface ActiveUsersModalProps {
  craftingViewersCount: number;
}

export interface ProductMobile {
  tokenAddress: string;
  quantityMin: string;
  quantityMax: string;
  nftValueMax: null | number;
  nftValueMin: null | number;
}

export interface UnclaimedRecipeProps {
  craftDuration: number;
  craftStartTime: string;
  craftEndTime: string;
  products: ProductMobile[];
  recipeId: number;
  multiplyModifier: number;
  unclaimedModifier: number;
  userRecipeIndex: number;
  buildingHash: string;
}
