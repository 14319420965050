import type { Contract } from 'ethers';
import type {
  BuildingProps,
  CraftAvailableForClaim,
  RecipeProps,
  UnclaimedRecipeProps,
  UserActivityAvailableForClaim
} from '~/types/crafting';

export async function getUserActivitiesShort(
  recipes: UnclaimedRecipeProps[],
  address: string
): Promise<CraftAvailableForClaim[]> {
  const { blockchain } = useEnvs();
  const { getContract } = useAbiAccess();

  const craftingContract = await getContract('crafting', blockchain.contracts.crafting);
  const recipesStatuses = await Promise.all(
    recipes.map(async (recipe: UnclaimedRecipeProps) => {
      const data = await craftingContract?.getUserRecipeStatuses(address, recipe.recipeId, 0, 100);
      return {
        recipe,
        data
      };
    })
  );

  return recipesStatuses.flatMap(({ recipe, data }) => {
    const filteredData = data.filter((item: any) => Number(item[3]) > 0);
    if (filteredData.length === 0) return [];

    return [
      {
        recipe,
        available: filteredData.reduce((sum: number, item: any) => sum + Number(item[3]), 0),
        id: filteredData.map((item: any) => item[5])
      }
    ];
  });
}

// split indexes into arrays of 10 elements for Claim contract method
export function splitActivitiesToChunks(
  recipeIds: number[],
  indexes: Record<number, number>[]
): [number[], number[][]] {
  const recipeIdsFormatted: number[] = [];
  const indexesFormatted: number[][] = [];

  indexes.forEach((obj, index) => {
    const values = Object.values(obj);
    let chunk: number[] = [];

    values.forEach((value, i) => {
      chunk.push(value);

      if (chunk.length === 10 || i === values.length - 1) {
        indexesFormatted.push(chunk);
        recipeIdsFormatted.push(recipeIds[index]);
        chunk = [];
      }
    });
  });

  return [recipeIdsFormatted, indexesFormatted];
}

export async function getUserActivities(
  recipes: RecipeProps[],
  building: BuildingProps,
  address: string,
  craftingContract: Contract
) {
  let formattedValue: UserActivityAvailableForClaim[][] = [];

  try {
    const userActivity = await Promise.all(
      recipes?.map((recipe: RecipeProps) =>
        craftingContract?.getUserRecipeStatuses(address, recipe?.recipeId, 0, 100)
      ) || []
    );

    formattedValue = userActivity.map((recipeActivity, index) =>
      recipeActivity
        .map((activity: [number, number, number, number, string, number, RecipeProps]) => {
          return {
            index,
            date: Number(activity[0]),
            total: Number(activity[1]),
            acquired: Number(activity[2]),
            available: Number(activity[3]),
            buildingHash: activity[4],
            id: Number(activity[5])
          };
        })
        .filter((item: UserActivityAvailableForClaim) => {
          return item.buildingHash === building.buildingHash && item.available > 0;
        })
    );
    return formattedValue;
  } catch (error) {
    console.error(error);
  }

  return formattedValue;
}
