import { CraftingQueueStatus, CraftingQueueStatusMessage, QueueTypes } from '~/utils/constants';
import type { RecipeProps } from '~/types/crafting';
import { BigNumber } from 'bignumber.js';
import { useMainStore } from '~/stores/mainStore';

export default (
  recipe: Ref<RecipeProps | null, RecipeProps | null>,
  commonQueueLimit: Ref<number | number> | undefined,
  personalQueueLimit: Ref<number | number> | undefined
) => {
  const store = useMainStore();

  const availableRecipeForCraftStatus = ref<CraftingQueueStatus>(CraftingQueueStatus.REMAINING);
  const queueLimitStatus = ref<CraftingQueueStatus>(CraftingQueueStatus.REMAINING);
  const messageType = ref<CraftingQueueStatusMessage | null>(null);

  const resetStatuses = () => {
    availableRecipeForCraftStatus.value = CraftingQueueStatus.REMAINING;
    queueLimitStatus.value = CraftingQueueStatus.REMAINING;
    messageType.value = null;
  };

  watch(
    [recipe, commonQueueLimit, personalQueueLimit, () => recipe.value?.recipeId, () => store.notificationUpdateVersion],
    ([recipeValue, commonQueueLimitValue, personalQueueLimitValue]) => {
      if (
        !recipeValue ||
        !('duration' in recipeValue) ||
        !('queueType' in recipeValue) ||
        typeof recipeValue.duration !== 'number' ||
        typeof commonQueueLimitValue !== 'number' ||
        typeof personalQueueLimitValue !== 'number'
      ) {
        return;
      }

      resetStatuses();

      const canCraftSingleRecipe = BigNumber(recipeValue.duration || 0)
        .multipliedBy(1000)
        .isLessThan(commonQueueLimitValue);

      const isCommonQueueLimitReached = commonQueueLimitValue === 0 || !canCraftSingleRecipe;
      const isPersonalQueueLimitReached = !personalQueueLimitValue;

      if (recipeValue.queueType === QueueTypes.PERSONAL && isPersonalQueueLimitReached) {
        availableRecipeForCraftStatus.value = CraftingQueueStatus.REACHED;
        queueLimitStatus.value = CraftingQueueStatus.REACHED;
        messageType.value = CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD;
        return;
      }
      if (isCommonQueueLimitReached) {
        availableRecipeForCraftStatus.value = CraftingQueueStatus.REACHED;
        queueLimitStatus.value = CraftingQueueStatus.REACHED;
        messageType.value = CraftingQueueStatusMessage.COMMON_QUEUE_OVERLOAD;
        return;
      }

      if (isPersonalQueueLimitReached && !isCommonQueueLimitReached) {
        availableRecipeForCraftStatus.value = CraftingQueueStatus.REACHED;
        queueLimitStatus.value = CraftingQueueStatus.REMAINING;
        messageType.value = CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD;
        return;
      }
    },
    {
      immediate: true
    }
  );

  return {
    availableRecipeForCraftStatus,
    queueLimitStatus,
    messageType
  };
};
