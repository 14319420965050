<template>
  <div
    class="recipe-row"
    :class="[isUsersRecipe && 'usersRecipe', props.transactionHash && 'inProgress', isDone && 'isDone']"
  >
    <div v-if="props.runningRecipe" class="recipe-row-info">
      <div v-if="isSingleProductRecipe" class="recipe-row-token-info">
        <img
          :src="getIcon(Number(props.recipe?.recipeId), props.recipe?.products[0].tokenAddress || '')"
          :alt="props.tokenMetadata.label"
        />
        <h3>({{ props.runningRecipe.multiplyModifier }})</h3>
      </div>
      <div v-else class="recipe-row-token-info">
        <img
          :src="getIcon(Number(props.recipe?.recipeId), props.recipe?.products[0].tokenAddress || '')"
          :alt="props.tokenMetadata.label"
        />
        <h3>{{ $t(`${recipeIdPrefix}${recipe?.recipeId}`) }} ({{ props.runningRecipe.multiplyModifier }})</h3>
      </div>

      <div v-if="!isDone">
        <div class="recipe-row-token-info">
          <span style="margin-right: 5px">{{ `${$t('craftingStartsIn')} ` }}</span>

          <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="craftingStartTimestamp">
            {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
            {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
            {{
              BigNumber(days).isLessThan(1) &&
              (BigNumber(minutes).isGreaterThan(0) || BigNumber(hours).isGreaterThan(0))
                ? `${formatToZeroPrefixedNumber(minutes || 0)}m`
                : ''
            }}
            {{
              BigNumber(hours).isGreaterThan(0) || (BigNumber(minutes).isZero() && BigNumber(seconds).isZero())
                ? ''
                : `${formatToZeroPrefixedNumber(seconds || 0)}s`
            }}
          </vue-countdown>
        </div>
      </div>

      <div v-else class="recipe-row-done">
        <p>{{ $t('craftingTokensReceived') }}</p>
        <div class="recipe-row-close" />
      </div>
    </div>
    <div v-else-if="props.transactionHash" class="recipe-row-info">
      <div class="recipe-row-token-info">
        <img :src="store.craftingTokenImg" :alt="props.tokenMetadata.label" />
        <h3>{{ $t(`${recipeIdPrefix}${recipe?.recipeId}`) }}</h3>
      </div>
      <div class="recipe-row-queue">
        <div class="recipe-row-time" />
        <a :href="transactionURLRef" target="_blank">
          <div class="recipe-row-link"></div>
        </a>
      </div>
    </div>
    <div v-if="!isDone" class="recipe-row-progress"></div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import useEnvs from '~/composables/useEnvs';
import { type TTokensConfigItem } from '~/utils/constants';
import type { RecipeProps, RunningRecipeProps } from '~/types/crafting';
import { useMainStore } from '~/stores/mainStore';
import { useRuntimeConfig } from 'nuxt/app';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { BigNumber } from 'bignumber.js';

const props = defineProps<{
  runningRecipe?: RunningRecipeProps;
  recipe?: RecipeProps;
  tokenMetadata: TTokensConfigItem;
  transactionHash?: string;
}>();

const isSingleProductRecipe = computed(() => props.recipe?.products.length === 1);

const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();
const store = useMainStore();
const config = useRuntimeConfig();

const transactionURLRef = ref(`${blockchain.blockScanUrl}/tx/${props.transactionHash}`);

const isUsersRecipe = address.value === props.runningRecipe?.userAddress;
const recipeIdPrefix = computed(() => (config?.public.env === 'prod' ? 'prodRecipeId' : 'testnetRecipeId'));
const craftingStartTimestamp = computed(
  () => new Date(props.runningRecipe?.craftStartTime || '').getTime() - Date.now()
);
const isDone = false;
</script>

<style lang="scss">
p,
h3 {
  margin: 0;
}
.recipe-row {
  padding: 0 16px;
  margin-bottom: 16px;

  &.isDone {
    border-radius: 12px;
    background: #081b25;

    border: 1px solid #1e353e;
  }

  &.usersRecipe {
    .recipe-row-progress {
      border-color: #49ddaa;
    }
    .recipe-row-token-info {
      h3 {
        color: #fff;
      }
    }
  }

  &.inProgress {
    .recipe-row-progress {
      border-color: #ddcead;
      box-shadow: 0px 0px 2px 0px #ffe603;
    }
  }

  p {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  &-progress {
    border-radius: 8px;
    height: 4px;
    border-color: #10364a;
    width: 100%;
    border: 1px solid #10364a;
    background: linear-gradient(180deg, #17343e 0%, #010303 100%);
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-token-info {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;

    h3 {
      font-family: Grenze;
      font-weight: 600;
      text-align: left;
      color: #90989d;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    span {
      white-space: nowrap;
    }

    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
      border-radius: 10px;
    }
  }

  &-done {
    display: flex;
    align-items: center;

    p {
      font-family: Grenze;
      font-size: 20px;
      font-weight: 500;
      line-height: 29.6px;
      text-align: left;
      color: #49ddaa;
      margin-right: 15px;
    }
  }

  &-time-data {
    p {
      color: #90989d;
      font-family: Grenze;
      font-size: 20px;
      font-weight: 500;
      line-height: 29.6px;
    }

    span {
      color: #fff;
    }
  }

  &-queue {
    display: flex;
    justify-content: space-between;
    width: 45px;
    margin-left: auto;
    margin-bottom: 10px;
  }

  &-close,
  &-time,
  &-link {
    cursor: pointer;

    background-image: url('~/public/img/icons/bone_close.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    &:hover {
      opacity: 0.6;
    }
  }

  &-time {
    background-image: url('~/public/img/icons/time.svg');
    cursor: default;
    width: 14px;
    height: 20px;
  }

  &-link {
    background-image: url('~/public/img/icons/link.svg');
  }
}
</style>
