import useEnvs from '~/composables/useEnvs';
import type { BuildingProps, RecipeProps } from '~/types/crafting';

export default async (
  recipeToCraft: Ref<RecipeProps | null, RecipeProps | null>,
  building: Ref<BuildingProps> | undefined
) => {
  const { getContractReadOnly } = useAbiAccess();
  const { blockchain } = useEnvs();

  const maxQueuePerUser = ref(0);
  const isLoading = ref(false);

  watch(
    [() => recipeToCraft.value?.recipeId, () => building?.value.buildingHash],
    async () => {
      try {
        isLoading.value = true;

        const craftingContract = await getContractReadOnly('crafting', blockchain.contracts.crafting);
        const mapContract = await getContractReadOnly('map', blockchain.contracts.map);

        const { buildingNameHash } = await mapContract.getLandBuilding(building?.value.buildingHash);

        const { maxQueuePerUser: maxQueuePerUserValue } = await craftingContract.getMaxQueueForRecipeInBuilding(
          buildingNameHash,
          recipeToCraft.value?.recipeId
        );

        maxQueuePerUser.value = Number(maxQueuePerUserValue);
      } catch (error) {
        console.error(error, 'error on getting max queue per user');
      } finally {
        isLoading.value = false;
      }
    },
    {
      immediate: true
    }
  );

  return {
    maxQueuePerUser,
    isLoading
  };
};
