<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1239 10.9999C20.1239 16.0389 16.039 20.1239 11 20.1239C5.96094 20.1239 1.87601 16.0389 1.87601 10.9999C1.87601 5.96088 5.96094 1.87594 11 1.87594C16.039 1.87594 20.1239 5.96088 20.1239 10.9999ZM21.9091 10.9999C21.9091 17.0248 17.0249 21.909 11 21.909C4.97505 21.909 0.0908813 17.0248 0.0908813 10.9999C0.0908813 4.97499 4.97505 0.0908203 11 0.0908203C17.0249 0.0908203 21.9091 4.97499 21.9091 10.9999ZM10.3088 16.4762C10.5298 16.6623 10.6753 16.7554 10.7451 16.7554C10.8731 16.7554 11.5306 16.6449 12.7175 16.4238V15.586L12.0542 15.3765V9.18015C12.0542 9.09869 11.9553 8.96487 11.7575 8.77869C11.5597 8.59251 11.4258 8.49942 11.356 8.49942C11.228 8.49942 10.5473 8.60415 9.31385 8.8136V9.65142L9.97712 9.86088V16.0747C9.97712 16.1445 10.0877 16.2783 10.3088 16.4762ZM11.5829 5.14815L9.83749 5.25287L9.97712 7.20778H11.7924L11.9146 5.58451L11.5829 5.14815Z"
      fill="white"
    />
  </svg>
</template>
