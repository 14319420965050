<template>
  <CraftingDescriptionPopup
    v-if="$device.isMobile && isModalOpen"
    :title="$t('overloadQueueTitle')"
    @toggle="toggleQueueOverloadModal"
  >
    <CraftingQueueOverloadedContent v-if="messageType" :message-type="messageType" />
  </CraftingDescriptionPopup>
  <el-skeleton :loading="isLoading || recipeRequestStatus === 'pending'" animated>
    <template #template>
      <div class="crafting-info-duration-skeleton">
        <el-skeleton-item style="height: 24px; width: 95%" />
      </div>
    </template>
    <template #default>
      <div class="crafting-info-duration">
        <p v-if="recipeToCraft && recipeToCraft.duration > 0" class="crafting-info-row-time">
          <el-tooltip
            v-if="isQueueOverloaded"
            popper-class="popper-default"
            :disabled="$device.isMobile"
            :trigger="'click'"
          >
            <template #content>
              <CraftingQueueOverloadedContent v-if="messageType" :message-type="messageType" />
            </template>
            <span
              class="overload"
              :class="{
                blink: isAnimateBlinkInjected && messageType === CraftingQueueStatusMessage.COMMON_QUEUE_OVERLOAD
              }"
              @click="toggleQueueOverloadModal"
              @mouseover="handleMouseOver"
            >
              {{
                messageType === CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD
                  ? $t('queuePersonalOverloaded')
                  : $t('queueOverloaded')
              }}
            </span>
          </el-tooltip>

          <template v-else-if="!isEnoughTokens">
            <span class="crafting-resources-not-enough">
              {{ $t('notEnoughResources') }}
            </span>
          </template>

          <template v-else>
            {{ $t('willBeReadyIn') }}:
            <CraftingReadyTimeCountDown :time="readyTime" />
          </template>
        </p>

        <span v-else-if="!address" class="crafting-info-row-time">
          <span class="crafting-resources-not-enough">{{ $t('notEnoughResources') }}</span>
        </span>
        <span v-else class="crafting-info-row-time"> {{ $t('craftingTime') }}: {{ $t('instant') }}</span>
      </div>
    </template>
  </el-skeleton>
</template>

<script setup lang="ts">
import CraftingQueueOverloadedContent from '~/components/Crafting/CraftingQueueOverloadedContent.vue';
import CraftingReadyTimeCountDown from '~/components/Crafting/CraftingReadyTimeCountDown.vue';
import { inject } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import type { RecipeProps } from '~/types/crafting';
import { CraftingQueueStatusMessage } from '~/utils/constants';

defineProps<{
  readyTime: number;
}>();

const { address } = useWeb3ModalAccount();
const [isModalOpen, toggleModal] = useToggle();
const { data: recipeToCraft } = useNuxtData<RecipeProps>('recipes/recipe');

const queueTimeLimitInjected = inject<Ref<number>>('queueTimeLimit');
const recipeRequestStatus = inject<Ref<string>>('recipeRequestStatus');
const isAnimateBlinkInjected = inject<Ref<boolean>>('isAnimatedBlink');
const animateBlinkInjected = inject<() => void>('animateBlink');
const toggleMaxHeightCraftingModalInjected = inject<() => void>('toggleMaxHeightCraftingModal');

const maxAmountAvailableForCraft = inject<Ref<number>>('maxAmountAvailableForCraft');
const isEnoughTokens = inject<Ref<boolean>>('isEnoughTokens');
const isLoading = inject<Ref<boolean>>('isMaxAmountAvailableForCraftLoading');

const { messageType } = useQueueLimitStatus(recipeToCraft, queueTimeLimitInjected, maxAmountAvailableForCraft);

const handleMouseOver = () => {
  if (messageType.value === CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD) {
    return;
  }

  animateBlinkInjected && animateBlinkInjected();
};

const isQueueOverloaded = computed(
  () =>
    (messageType.value === CraftingQueueStatusMessage.PERSONAL_QUEUE_OVERLOAD ||
      messageType.value === CraftingQueueStatusMessage.COMMON_QUEUE_OVERLOAD) &&
    isEnoughTokens?.value
);

const toggleQueueOverloadModal = () => {
  toggleMaxHeightCraftingModalInjected && toggleMaxHeightCraftingModalInjected();
  toggleModal();
};
</script>

<style scoped lang="scss">
.crafting-info-duration-skeleton {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.crafting-info-duration {
  .overload {
    cursor: pointer;
    color: var(--error);
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}

.crafting-resources-not-enough {
  color: var(--error);
}

.crafting-info-row-time {
  font-family: Eczar;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  padding: 18px 0;

  .crafting-info-row-time-ready {
    color: #49ddaa;
  }
}
</style>
